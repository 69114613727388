import Global from "../Initializers"
import React, { Component } from 'react';
import { HashRouter, Route, Router, Switch, Redirect } from 'react-router-dom';
import $ from 'jquery';
import { WebGraph } from "../GenericCoreGraph/WebGraph/WebGraph"
import GenericWebGraph from "../GenericWebController/GenericWebGraph"
import Loading from "../TagComponents/Utilities/Loading"
import { ObjectTypes } from "../GenericCoreGraph/ClassFramework/Class"
//import Login from "../../views/Pages/Login"
import '../../App.scss';

const TLogin = React.lazy(() => import("./TLogin"));
const DefaultLayout = React.lazy(() => import('../../containers/DefaultLayout'));
const TMessageBox = React.lazy(() => import("../TagComponents/Listeners/TMessageBox"));
const THotSpotMessage = React.lazy(() => import("../TagComponents/Listeners/THotSpotMessage"));
const TLogoutListener = React.lazy(() => import("../TagComponents/Listeners/TLogoutListener"));
const TGlobalLoading = React.lazy(() => import("../TagComponents/Utilities/TGlobalLoading"));



export default class TApp extends Component
{
  constructor(props)
  {
    super();
  }

  componentWillMount()
  {
    WebGraph.Init();
    GenericWebGraph.Init();
    this.HashControlFunction(this.HashChanged);
  }

  HashChanged()
  {
    if (GenericWebGraph.User == null)
    {
      window.location.href = "#/";
    }
  }

  HashControlFunction(_Function) {
    if (("onhashchange" in window)) {
      window.onhashchange = function () {
        _Function(window.location.hash);
      }
    }
    else {
      var prevHash = window.location.hash;
      window.setInterval(function () {
        if (window.location.hash != prevHash) {
          prevHash = window.location.hash;
          _Function(window.location.hash);
        }
      }, 100);
    }
  }


  componentDidUpdate(prevProps, prevState)
  {
    console.log(window.location);
    var __pathname = this.props.location;
    var __pathnameprev = prevProps.location;

  }

    render() {        
                
      return (
        <React.Suspense fallback={<Loading />}>
          <div>
            <THotSpotMessage />
            <TMessageBox />
            <TGlobalLoading />
              <Switch>
                  <Route path="/" name="Ana Sayfa" render={props => (
                    GenericWebGraph.User == null ? (<TLogin {...props} />) : (<DefaultLayout {...props} />)
                  )} />
              <Route exact path="/login" name="Ana Sayfa" render={props => <TLogin {...props} />} />
              </Switch>
          </div> 
        </React.Suspense>
        );
    }
}


//this.Message = "Hayri";
//this.TempTimer = new cTimer();
//this.Deneme = this.CreateDeneme();
//this.Counter = 0;
//var __This = this;
//var __TempList = new cList(ObjectTypes.Deneme);
//this.TempTimer.AddFunctionToEvent(function (_Temp) {
//    __This.Counter++;
//    console.log("Deneme -> " + __This.Counter);
//    var __Item = WebGraph.GetItemByCID(10);
//    if (__Item != null) {
//        __Item.SetValue(__This.Counter);
//    }
//    else {
//        __This.TempTimer.Stop();

//    }



//    if (!__TempList.IsContain(__Item)) {
//        __TempList.Add(__Item);
//    }

//    if (cBaseObject.IsAssignableFrom(__Item)/*WebGraph.ControlBaseClass(__Item, ObjectTypes.cBaseObject)*/) {
//        __This.Message = "__Item -> extended from cBaseObject";
//    }
//    else {
//        __This.Message = "__Item -> not extended from cBaseObject";
//    }
//    __This.forceUpdate();

//    //WebGraph.ShowObjectList();
//});
//this.TempTimer.SetInterval(1000);
//this.TempTimer.Start();
