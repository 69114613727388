import React, { Component } from 'react';
import { DebugAlert, Class, Interface, Abstract, ObjectTypes, JSTypeOperator } from "../ClassFramework/Class"
import { WebGraph } from "../WebGraph/WebGraph"


var cBaseObject = Class(Component, {

    CreateID: 0
    , CID: 0
    , ObjectType: ObjectTypes.cBaseObject
    ,    
    constructor: function (_Props)
    {
        cBaseObject.BaseObject.constructor.call(this, _Props);
        this.CreateID = WebGraph.GetNewCreateID();
        this.CID = parseInt(_Props ?  (_Props.cid ? _Props.cid : -1) : -1);
    },
    GetObjectType: function()
    {
        return this.ObjectType;
    }
    ,
    GetTypeID: function()
    {
        return this.ObjectType.ObjectTypeID;
    },
    GetCreateID: function()
    {
        return this.CreateID;
    },
    ToString: function()
    {
        return this.ObjectType.ObjectName;
    }
    ,
    BaseObject: function()
    {
        return Object;
    }
    ,
    componentDidMount: function ()
    {
      WebGraph.Add(this);
    }
    ,
    componentWillUnmount()
    {
        this.Destroy();
    }
    ,
    componentWillMount: function ()
    {
    }
    ,
    Destroy: function()
    {
        WebGraph.Remove(this);
        delete this.CreateID;
        delete this.ObjectType;
    }
}, {});

export default cBaseObject



