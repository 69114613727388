String.prototype.format = function() 
{
    var __Formatted = this;
    for (var i = 0; i < arguments.length; i++) {
        var regexp = new RegExp('\\{'+i+'\\}', 'gi');
        __Formatted = __Formatted.replace(regexp, arguments[i]);
    }
    return __Formatted;
};

String.prototype.trim=function(){return this.replace(/^\s+|\s+$/g, '');};

if (!String.prototype.startsWith) {
  Object.defineProperty(String.prototype, 'startsWith', {
    value: function (search, pos) {
      pos = !pos || pos < 0 ? 0 : +pos;
      return this.substring(pos, pos + search.length) === search;
    }
  });
}

var Global = function()
{
}

export default Global



