import React from 'react';
import { JSTypeOperator, DebugAlert, Class, Interface, Abstract, ObjectTypes, cListForBase } from "../GenericCoreGraph/ClassFramework/Class"
import cCommandInterpreter from "./CommandInterpreter/cCommandInterpreter"
import cActionGraph from "./ActionGraph/cActionGraph"


//var cCommandListenerGraph = React.lazy(() => import(""));

function GenericWebGraph() 
{
}

GenericWebGraph.Init = function()
{
  GenericWebGraph.CommandInterpreter = new cCommandInterpreter();
  GenericWebGraph.ActionGraph = new cActionGraph();
  import('./CommandListenerGraph/cCommandListenerGraph')
    .then((module) => {
      var cCommandListenerGraph = module.default;
      GenericWebGraph.CommandListenerGraph = new cCommandListenerGraph();    
    });

  

}

GenericWebGraph.User = null;
GenericWebGraph.SessionID = "";
GenericWebGraph.GlobalLoading = null;
GenericWebGraph.CurrentStates =
{
  Coordinate: {
    lat: 39.934272,
    lng: 32.853768
  },
  CoordinateSelected : false
};
GenericWebGraph.DefaultAside = null;
GenericWebGraph.DefaultHeader = null;
GenericWebGraph.HotSpotMessage = null;
GenericWebGraph.MessageBox = null;
GenericWebGraph.Maps = null;


GenericWebGraph.GetHost = function() 
{
	var __Url = window.location.href;
	var __NoHttp = __Url.split('//')[1];
	return __NoHttp.split('/')[0];
}


export default GenericWebGraph
