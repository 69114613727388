import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { JSTypeOperator, cObjType, DebugAlert, Class, Interface, Abstract, ObjectTypes, GlobalEval } from "../../../GenericCoreGraph/ClassFramework/Class"
import CID from "./CID"
import GenericWebGraph from "../../GenericWebGraph"
import Actions from "../../ActionGraph/Actions"


var cAction = Class(Component,
{
	ActionCreater: null
	, JsonableObject: null
	, ResultFunction : null
	,
	constructor: function (_ActionCreater, _JsonableObject, _ResultFunction)
	{

		this.ActionCreater = _ActionCreater;
		this.ResultFunction = _ResultFunction;
		
		if (_JsonableObject)
		{
			this.JsonableObject = _JsonableObject;
		}
		
    this.ActionCreater.ActionGraph.AddAction(this);
	}
	,
	BaseObject: function ()
	{
      return Component.prototype;
	}
	,
	Destroy: function ()
	{
		delete this.LoginedAction;
		delete this.JsonableObject;
		delete this.ActionCreater;
	}
	,
	Action: function ()
	{
		try
		{
			if (this.JsonableObject != null)
            {
              if (GenericWebGraph.GlobalLoading != null && Actions.IsLoadingEnabled) GenericWebGraph.GlobalLoading.Show();
              var __CID = new CID(this.ActionCreater.ActionID.ID, this.JsonableObject, GenericWebGraph.SessionID);
              var __This = this;
              fetch('api/WebApi/WebApi', {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(__CID)
              })
              .then(response => response.json())
              .then(response => {
                if (GenericWebGraph.GlobalLoading != null) GenericWebGraph.GlobalLoading.Hide();
                try {
                  if (JSTypeOperator.IsFunction(__This.ResultFunction)) {
                    var __ResultValue = __This.ResultFunction(response);
                    if (!JSTypeOperator.IsDefined(__ResultValue) || __ResultValue) {
                      try {
                        GenericWebGraph.CommandInterpreter.InterpretCommand(response);
                      }
                      catch (_Ex) {
                        DebugAlert.Show("CommandInterpreter'a data gelmedi..!");
                      }
                    }
                  }
                  else {
                    try {
                      GenericWebGraph.CommandInterpreter.InterpretCommand(response);
                    }
                    catch (_Ex) {
                      DebugAlert.Show("CommandInterpreter'a data gelmedi..!");
                    }
                  }
                }
                catch (_Ex) {
                  DebugAlert.Show("Action sonrası çalışacak fonksiyonda sorun var!");
                }

              }).catch(err => console.log(err));
				//cGenericWebGraph.SendJsonAjax(cGenericWebGraphGlobal.WebApi, __CID.toJSONString(), this.ResultFunction);	
			}
			else
			{
				DebugAlert.Show(this.ActionCreater.ActionID.ID + " Action da Boş Data Gönderilmeye Çalışılıyor..!");
			}
		}
		catch(e)
		{
			DebugAlert.Show(this.ActionCreater.ActionID.ID + " Action da hata var..!");
		}
    }
  
  }, {});

export default cAction







