import { JSTypeOperator, DebugAlert, Class, Interface, Abstract, ObjectTypes, cListForBase } from "../../../GenericCoreGraph/ClassFramework/Class"
import cBaseObject from "../../../GenericCoreGraph/BaseObject/cBaseObject"


var cBaseCommand = Class(cBaseObject, {
	ObjectType: ObjectTypes.Get("cBaseCommand")
	, CommandInterpreter : null
	, Command : null
	, Enabled : true	
	, ReciverList : null
	, ReciverInterface : null
	, ReciverFunctionName : null
	,
	constructor: function (_CommandInterpreter)
	{
		cBaseCommand.BaseObject.constructor.call(this);
		this.CommandInterpreter = _CommandInterpreter;
		this.CommandInterpreter.AddCommand(this);		
		this.ReciverList = new cListForBase();

	}
	,
	BaseObject: function ()
	{
		return cBaseObject.prototype;
	}
	,
	Destroy: function ()
	{
	    delete this.ObjectType;
	    delete this.CommandInterpreter;
	    this.Command.Destroy();
	    delete this.Command;
	    delete this.m_Enabled;
	    this.ReciverList.Destroy();
	    delete this.ReciverList;
		cBaseObject.prototype.Destroy.call(this);
	}
	,
	Run: function (_MsgObject)
	{
		var __Count = this.ReciverList.Count();
		for (var i = 0; i < __Count; i++)
		{
			var __Item = this.ReciverList.GetItem(i);
			eval("__Item." + this.ReciverFunctionName + "(_MsgObject)");
		}
 }
  ,
  IsConnect: function (_Reciver) {
    if (this.ReciverInterface.IsInstance(_Reciver))
    {
      return this.ReciverList.Find(__Item => __Item == _Reciver);
    }
    else {
      DebugAlert.Show(this.Command.CommandInfo + " Komutuna Bağlanmak İsteniyor Fakat '" + this.ReciverFunctionName + "' Fonksiyonu Bulunamadı yada Interfaceden Türetilmemiş..!");
    }
  }
	,
	Connect : function(_Reciver)
	{
		if (this.ReciverInterface.IsInstance(_Reciver))
		{
			this.ReciverList.Add(_Reciver);
		}
		else
		{
			DebugAlert.Show(this.Command.CommandInfo + " Komutuna Bağlanmak İsteniyor Fakat '" + this.ReciverFunctionName + "' Fonksiyonu Bulunamadı yada Interfaceden Türetilmemiş..!");
		}
	}
	,
	Disconnect: function (_Reciver)
	{
		this.ReciverList.Remove(_Reciver);
	}
	
}, {});

export default cBaseCommand






