import React from 'react';
import { Spinner } from 'reactstrap';

var Loading = function () {
  return (
    <div className="animated fadeIn pt-3 text-center"><h2>Yükleniyor... {' '}<Spinner color="primary" /></h2></div>
  );
}

export default Loading

