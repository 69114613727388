import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import './index.css';
//import App from './App';
import TApp from './WebGraph/TagComponents/TApp';
//import { TGoogleMaps } from "./WebGraph/TagComponents/Maps/TGoogleMaps"
//import  SimpleMap from "./WebGraph/TagComponents/Maps/SimpleMap"

//import { TMaps } from "./WebGraph/TagComponents/Maps/TMaps"

import * as serviceWorker from './serviceWorker';



ReactDOM.render(<HashRouter><TApp /></HashRouter>, document.getElementById('root'));

/*<TGoogleMaps
  isMarkerShown
  googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
  loadingElement={<div style={{ height: `100%` }} />}
  containerElement={<div style={{ height: `400px` }} />}
  mapElement={<div style={{ height: `100%` }} />}
/>*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
